<template>
  <div id="table-notifications">
    <h3 v-if="this.pumpRows.pumpData.length > 0">
      Notifications ({{ this.pumpRows.pumpData.length }})
    </h3>
    <h3 v-else>Notifications</h3>
    <div class="table-responsive">
      <b-table class="table" hover :items="pumpRows.pumpData" :fields="fields">
        <!--Specific data for a cell: Identifier-->
        <template #cell(qc_number)="data">
          <b-input-group v-if="data.value == '' || data.value == null">
            -
          </b-input-group>
          <b-input-group v-else> #{{ data.value }} </b-input-group>
        </template>

        <!--Specific data for a cell: Identifier-->
        <template #cell(location)="data">
          <b-input-group v-if="data.value == '' || data.value == null">
            -
          </b-input-group>
          <b-input-group v-else> #{{ data.value }} </b-input-group>
        </template>

        <!--Specific data for a cell: STATUS-->
        <template #cell(status)="data">
          <b-icon
            v-if="data.value == 'online'"
            icon="circle-fill"
            variant="success"
          ></b-icon>
          <b-icon v-else icon="circle-fill" variant="danger"></b-icon>
        </template>

        <!--Specific data for a cell: POWER (PLUG OR BATTERY)-->
        <template #cell(power_source)="data">
          <b-input-group v-if="data.value == 'Battery'">
            <b-icon
              icon="circle-fill"
              variant="danger"
              class="mt-1 mr-1"
            ></b-icon>
            Battery
          </b-input-group>

          <b-input-group v-else-if="data.value == 'Mains'">
            Mains
          </b-input-group>

          <b-input-group v-else>
            -
          </b-input-group>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: function() {
    return {
      fields: [
        { key: "qc_number", label: "QC number", sortable: true },
        { key: "location", sortable: true },
        { key: "status", label: "System status" },
        { key: "power_source", label: "Power" },
      ],
      pumpRows: {
        pumpRegistered: [],
        pumpData: [],
      },
    };
  },
  methods: {
    getData() {
      axios
        .get("https://horse.spinnov.com:1880/pumpGetData")
        .then((response) => {
          this.pumpRows.pumpData = response.data;

          //Sort data on pump id
          this.pumpRows.pumpData.sort(this.compare);

          for (var i = this.pumpRows.pumpData.length - 1; i >= 0; i--) {
            this.pumpRows.pumpData[i].location = this.pumpRows.pumpRegistered[
              i
            ].location;
            if (
              this.pumpRows.pumpData[i].status == "online" &&
              this.pumpRows.pumpData[i].power_source == "Mains"
            ) {
              this.pumpRows.pumpData.splice(i, 1);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    compare(a, b) {
      if (a.pump_id < b.pump_id) {
        return -1;
      }
      if (a.pump_id > b.pump_id) {
        return 1;
      }
      return 0;
    },
    getRegisteredPumps() {
      axios
        .get("https://horse.spinnov.com:1880/registeredPumps")
        .then((response) => {
          this.pumpRows.pumpRegistered = response.data;

          //Format registration date
          for (var i = 0; i < this.pumpRows.pumpRegistered.length; i++) {
            //Add data from registration table: location
            this.pumpRows.pumpRegistered[i].date = this.pumpRows.pumpRegistered[
              i
            ].date.substring(
              0,
              this.pumpRows.pumpRegistered[i].date.indexOf(".")
            );
            this.pumpRows.pumpRegistered[i].date = this.pumpRows.pumpRegistered[
              i
            ].date.replace("T", " ");
          }

          //Sort data on pump id
          this.pumpRows.pumpRegistered.sort(this.compare);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  //when loading, will load getlist method
  created() {
    //Get registration data
    this.getRegisteredPumps();
    //Get pump data
    this.getData();
  },
};
</script>

<style scoped>
#table-notifications {
  width: 80%;
  margin: 0 auto;
}
</style>
