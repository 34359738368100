<template>
    <div class="devicemanager-container">
        <div v-if="!deviceManagerIsHidden">
            <h3>Device Manager</h3>
            <div class="device-buttons d-flex justify-content-center m-5">
                <b-button variant="primary" class="px-5 pt-3 pb-3 m-5 w-25" @click="showForm()">
                    <b-icon icon="plus-circle"></b-icon>
                    Register New Device
                </b-button>
                <b-button variant="primary" class="px-5 pt-3 pb-3 m-5 w-25" @click="showUnregister()">
                    <b-icon icon="dash-circle"></b-icon>
                    Unregister Device
                </b-button>
            </div>
        </div>

        <!--Register new device-->
        <register-device v-if="!isHidden" v-on:cancelRegister="showForm()"/>

        <!--Unregister device-->
        <unregister-device v-if="!unregisterIsHidden" v-on:cancelUnregister="showUnregister()"/>
    </div>
</template>

<script>
import registerDevice from "./registerdevice.vue"
import unregisterDevice from "./unregisterdevice.vue"

export default {
    components: {
        registerDevice,
        unregisterDevice,
    },
    data: function () {
        return {
            isHidden: true,
            unregisterIsHidden: true,
            deviceManagerIsHidden: false,
        };
    },
    methods: {
        showForm () {
            this.isHidden = !this.isHidden
            this.deviceManagerIsHidden = !this.deviceManagerIsHidden
        },
        showUnregister () {
            this.unregisterIsHidden = !this.unregisterIsHidden
            this.deviceManagerIsHidden = !this.deviceManagerIsHidden
        }
    }
}
</script>

<style scoped>
.devicemanager-container {
    width: 80%;
    margin: 0 auto;
}
</style>