<template>
  <div>
    <b-button v-b-toggle.sidebar-border class="menu-button">
      <b-icon
        v-if="this.items.length > 0"
        icon="exclamation-triangle-fill"
        variant="danger"
      ></b-icon>
      <b-icon v-else icon="list"></b-icon>
      Menu
    </b-button>

    <b-sidebar
      id="sidebar-border"
      sidebar-class="border-right border-secondary"
    >
      <div class="px-3 py-2">
        <b-img
          id="logo"
          src="https://www.qcare.nl/wp-content/uploads/2020/09/QCare-WD-logo-e1602621561271.jpg"
          fluid
          thumbnail
        ></b-img>
        <b-button
          v-if="this.monitor"
          @click="deviceMonitorUrl"
          class="nav-buttons"
          variant="primary"
          >Device Monitor</b-button
        >
        <b-button
          v-else
          @click="deviceMonitorUrl"
          class="nav-buttons"
          variant="secondary"
          >Device Monitor</b-button
        >

        <b-button
          v-if="this.notification"
          @click="notificationUrl"
          class="nav-buttons"
          variant="primary"
          >Alarm Notifications
          <b-badge variant="light">{{ this.items.length }}</b-badge>
        </b-button>
        <b-button
          v-else
          @click="notificationUrl"
          class="nav-buttons"
          variant="secondary"
          >Alarm Notifications
          <b-badge variant="light">{{ this.items.length }}</b-badge>
        </b-button>

        <b-button
          v-if="this.manager"
          @click="deviceManagerUrl"
          class="nav-buttons"
          variant="primary"
          >Device Manager</b-button
        >
        <b-button
          v-else
          @click="deviceManagerUrl"
          class="nav-buttons"
          variant="secondary"
          >Device Manager</b-button
        >
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: function() {
    return {
      monitor: true,
      notification: false,
      manager: false,
      items: [],
    };
  },
  //urls
  methods: {
    deviceMonitorUrl() {
      this.$emit("monitor");
      this.monitor = true;
      this.notification = false;
      this.manager = false;
    },
    notificationUrl() {
      this.$emit("notifications");
      this.monitor = false;
      this.notification = true;
      this.manager = false;
    },
    deviceManagerUrl() {
      this.$emit("manager");
      this.monitor = false;
      this.notification = false;
      this.manager = true;
    },
    getData() {
      axios
        .get("https://horse.spinnov.com:1880/pumpGetData")
        .then((response) => {
          this.items = response.data;
          for (var i = this.items.length - 1; i >= 0; i--) {
            if (
              this.items[i].status == "online" &&
              this.items[i].power_source == "Mains"
            ) {
              this.items.splice(i, 1);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  //when loading, will load getlist method
  created() {
    this.getData();
  },
};
</script>

<style scoped>
#logo {
  margin-bottom: 30px;
}

.nav-buttons {
  width: 100%;
  margin-top: 10px;
}

.menu-button {
  margin-top: 10px;
  margin-left: 10px;
}
</style>
