<template>
  <div id="table-overview">
    <h3>Device Overview</h3>
    <!--Searchbar-->
    <div id="searchbar">
      <b-form inline class="justify-content-between">
        <div class="float-left d-inline-flex">
          <b-input-group class="mb-2 mr-sm-2 mb-sm-0 w-50">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              placeholder="Search terms"
            ></b-form-input>
          </b-input-group>
          <b-button variant="primary" id="search-button">Search</b-button>
        </div>
      </b-form>
    </div>

    <div class="table-responsive">
      <b-table
        class="table"
        hover
        :items="pumpRows.pumpData"
        :fields="fields"
        @row-clicked="onRowClicked"
      >
        <!--Specific data for a cell: Identifier-->
        <template #cell(qc_number)="data">
          <b-input-group v-if="data.value == '' || data.value == null">
            -
          </b-input-group>
          <b-input-group v-else> {{ data.value }} </b-input-group>
        </template>

        <!--Specific data for a cell: STATUS-->
        <template #cell(status)="data">
          <b-icon
            v-if="data.value == 'online'"
            icon="circle-fill"
            variant="success"
          ></b-icon>
          <b-icon v-else icon="circle-fill" variant="danger"></b-icon>
        </template>

        <!--Specific data for a cell: LAST UPDATE-->
        <template #cell(last_update)="data">
          <b-icon
            v-if="data.value == 'online'"
            icon="circle-fill"
            variant="success"
          ></b-icon>
          <b-icon v-else icon="circle-fill" variant="danger"></b-icon>
        </template>

        <!--Specific data for a cell: POWER (PLUG OR BATTERY)-->
        <template #cell(power_source)="data">
          <b-input-group v-if="data.value == 'Battery'">
            <b-icon
              icon="circle-fill"
              variant="danger"
              class="mt-1 mr-1"
            ></b-icon>
            Battery
          </b-input-group>

          <b-input-group v-else-if="data.value == 'Mains'">
            Mains
          </b-input-group>

          <b-input-group v-else> - </b-input-group>
        </template>

        <!--Specific data for a cell: TIME-->
        <template #cell(pump_time_of_operation)="data">
          <b-input-group v-if="data.value == '' || data.value == null">
            -
          </b-input-group>

          <b-input-group v-else>
            {{ data.value }}
          </b-input-group>
        </template>

        <!--Specific data for a cell: sit sensor-->
        <template #cell(sit_sensor)="data">
          <b-input-group v-if="data.value == '' || data.value == null">
            -
          </b-input-group>
          <b-input-group v-else> {{ data.value }} </b-input-group>
        </template>

        <!--Specific data for a Current of the Pump -->
        <template #cell(current)="data">
          <b-input-group v-if="data.value == '' || data.value == null">
            -
          </b-input-group>
          <b-input-group v-else> {{ data.value }} mA </b-input-group>
        </template>

        <!--Specific data for a cell: Battery voltage-->
        <template #cell(battery_voltage)="data">
          <b-input-group v-if="data.value == '' || data.value == null">
            -
          </b-input-group>
          <b-input-group v-else> {{ data.value }} V </b-input-group>
        </template>

        <!--Specific data for a cell: Air Pressure-->
        <template #cell(pressure)="data">
          <b-input-group v-if="data.value == '' || data.value == null">
            -
          </b-input-group>
          <b-input-group v-else> {{ data.value }} psi </b-input-group>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default {
  data: function() {
    return {
      fields: [
        { key: "qc_number", label: "QC number", sortable: true },
        { key: "location", sortable: true },
        { key: "registration_date" },
        { key: "status", label: "System status" },
        { key: "time", label: "Last update" },
        { key: "pump_time_of_operation", label: "Time of Operation" },
        { key: "power_source", label: "Power" },
        "sit_sensor",
        "battery_voltage",
        "pressure",
        "current",
      ],
      pumpRows: {
        pumpRegistered: [],
        pumpData: [],
      },
      connection: {
        host: "horse.spinnov.com",
        port: 1880,
        endpoint: "/wss/pumpdata",
      },
    };
  },
  methods: {
    onRowClicked(item) {
      window.open(item.grapahana, "_blank");
    },
    getData() {
      axios
        .get("https://horse.spinnov.com:1880/registeredPumps")
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("https://horse.spinnov.com:1880/pumpGetData")
        .then((response) => {
          //Update Vue component data
          this.pumpRows.pumpData = response.data;

          //Sort data on pump id
          this.pumpRows.pumpData.sort(this.compare);

          //Loop over data of all pumps
          for (var i = 0; i < this.pumpRows.pumpData.length; i++) {
            //Format registration date
            let dateString = this.pumpRows.pumpRegistered[i].time.replace(
              " ",
              "T"
            );

            let date = new Date(dateString);
            let minutes =
              date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes();

            let seconds =
              date.getSeconds() < 10
                ? "0" + date.getSeconds()
                : date.getSeconds();

            dateString =
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getDate() +
              " " +
              date.getHours() +
              ":" +
              minutes +
              ":" +
              seconds;

            //Add data from registration table: date
            this.pumpRows.pumpData[i].registration_date = dateString;

            //Add data from registration table: location
            this.pumpRows.pumpData[i].location = this.pumpRows.pumpRegistered[
              i
            ].location;

            //Replace any NaN with '-'
            for (const field in this.pumpRows.pumpData[i]) {
              if (this.pumpRows.pumpData[i][field] === "NaN") {
                this.pumpRows.pumpData[i][field] = "-";
              }
            }

            //hyperlink to graphana
            this.pumpRows.pumpData[i].grapahana =
              "http://qcare-backend.spinnov.com:3000/d/1jcmxoR7z/qcare_pump_demo?orgId=1&var-qc_number=" +
              this.pumpRows.pumpData[i].qc_number;

            //!!hardcoded removal! of minus signs!
            if (this.pumpRows.pumpData[i].pump_time_of_operation) {
              if (
                this.pumpRows.pumpData[i].pump_time_of_operation.includes("-")
              )
                this.pumpRows.pumpData[
                  i
                ].pump_time_of_operation = this.pumpRows.pumpData[
                  i
                ].pump_time_of_operation.replace("-", "");
            }

            dateString = this.pumpRows.pumpData[i].time.replace(" ", "T");

            date = new Date(dateString);
            minutes =
              date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes();

            seconds =
              date.getSeconds() < 10
                ? "0" + date.getSeconds()
                : date.getSeconds();

            dateString =
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getDate() +
              " " +
              date.getHours() +
              ":" +
              minutes +
              ":" +
              seconds;

            //Format last update
            this.pumpRows.pumpData[i].time = dateString;

            //Reduce decimals of battery voltage to 2
            if (this.pumpRows.pumpData[i].battery_voltage) {
              this.pumpRows.pumpData[
                i
              ].battery_voltage = this.pumpRows.pumpData[
                i
              ].battery_voltage.toFixed(2);
            }

            Vue.set(this.pumpRows.pumpData[i], "_showDetails", false);

            //convert to Day, Hours, Minutes
            var days = Math.floor(
              this.pumpRows.pumpData[i].pump_time_of_operation / 1440
            );
            var excludeDays =
              this.pumpRows.pumpData[i].pump_time_of_operation - 1440 * days;
            var time =
              days +
              "d " +
              Math.floor(excludeDays / 60) +
              "h " +
              (excludeDays % 60) +
              "m";
            Vue.set(this.pumpRows.pumpData[i], "pump_time_of_operation", time);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    compare(a, b) {
      if (a.pump_id < b.pump_id) {
        return -1;
      }
      if (a.pump_id > b.pump_id) {
        return 1;
      }
      return 0;
    },
    getRegisteredPumps() {
      axios
        .get("https://horse.spinnov.com:1880/registeredPumps")
        .then((response) => {
          this.pumpRows.pumpRegistered = response.data;

          //Format registration date
          for (var i = 0; i < this.pumpRows.pumpRegistered.length; i++) {
            this.pumpRows.pumpRegistered[i].date = this.pumpRows.pumpRegistered[
              i
            ].date.substring(
              0,
              this.pumpRows.pumpRegistered[i].date.indexOf(".")
            );
            this.pumpRows.pumpRegistered[i].date = this.pumpRows.pumpRegistered[
              i
            ].date.replace("T", " ");
          }

          //Sort data on pump id
          this.pumpRows.pumpRegistered.sort(this.compare);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createConnection() {
      const { host, port, endpoint } = this.connection;
      const url = `wss://${host}:${port}${endpoint}`;

      this.connection = new WebSocket(url);

      this.connection.onmessage = () => {
        this.getData();
      };

      this.connection.onopen = function() {
        console.log("Successfully connected to the websocket server...");
      };
    },
  },
  created() {
    //Get registration data
    this.getRegisteredPumps();
    //Get latest pump data
    this.getData();
    //Create ws connection with node-red
    this.createConnection();
  },
};
</script>

<style scoped>
#table-overview {
  width: 80%;
  margin: 0 auto;
}

#searchbar {
  width: 100%;
  background-color: lightgrey;
  padding: 10px;
  border-radius: 4px;
}

#search-button {
  width: 150px;
}

#weight {
  visibility: hidden;
}
</style>
