<template>
  <div id="app">
    <sidebar
      v-on:monitor="monitorPage()"
      v-on:notifications="notificationsPage()"
      v-on:manager="managerPage()"
    />
    <devicemonitor v-if="this.page == 0" />
    <notifications v-else-if="this.page == 1" />
    <devicemanager v-else-if="this.page == 2" />
  </div>
</template>

<script>
import Devicemonitor from "./vue/devicemonitor";
import Sidebar from "./vue/sidebar";
import Notifications from "./vue/notifications";
import Devicemanager from "./vue/devicemanager";

export default {
  name: "App",
  components: {
    Devicemonitor,
    Sidebar,
    Notifications,
    Devicemanager,
  },
  data: function() {
    return {
      page: 0,
    };
  },
  methods: {
    monitorPage() {
      this.page = 0;
    },
    notificationsPage() {
      this.page = 1;
    },
    managerPage() {
      this.page = 2;
    },
  },
  created() {
    this.monitorPage();
  },
};
</script>
