<template>
  <div>
    <b-row class="mb-4">
      <h3>Register New Device</h3>
    </b-row>

    <!--Alert-->
    <b-alert v-model="succes" variant="success">
      <p>{{ response }}</p>
    </b-alert>

    <b-alert v-model="error" variant="danger">
      <p>{{ response }}</p>
    </b-alert>

    <!--Identifier and location-->
    <b-row class="mb-6 mt-6">
      <b-dropdown
        variant="warning"
        id="dropdown-1"
        v-model="selectedOption"
        style="background-color: #fd7f03 !important"
        text="QC number"
        class="mb-4 rounded"
      >
        <b-dropdown-item
          class="rounded"
          v-for="option in qcOptions"
          :key="option.value"
          :value="option.value"
          @click="selectedOption = option.value"
        >
          {{ option.value }}
        </b-dropdown-item>
      </b-dropdown>
      <section class="mb-4 pl-3" style="display: flex; align-items:center;">
        <span>Selected QC numer: {{ selectedOption }}</span>
      </section>
    </b-row>

    <b-row class="mb-4">
      <b-input-group class="mb-2 mr-sm-2 mb-sm-0" style="max-width: 400px">
        <b-input-group-prepend is-text>
          <b-icon icon="geo-alt"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          type="search"
          v-model="item.location"
          placeholder="Location"
        ></b-form-input>
      </b-input-group>
    </b-row>

    <b-row class="mb-4">
      <b-button variant="primary" class="mr-3" @click="registerDevice()"
        >Register Device</b-button
      >
      <b-button variant="secondary" class="ml-3 px-3" @click="cancel()"
        >Cancel</b-button
      >
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: function() {
    return {
      showDismissibleAlert: false,
      checked: false,
      qcOptions: [
        {
          value: "QC40080",
        },
        {
          value: "QC40072",
        },
        {
          value: "QC40398",
        },
        {
          value: "QC40227",
        },
        {
          value: "QC41616",
        },
      ],
      selectedOption: null,
      response: null,
      error: false,
      succes: false,
      item: {
        qcNumber: "",
        location: "",
      },
    };
  },
  methods: {
    registerDevice() {
      this.item.qcNumber = this.selectedOption;

      axios
        .post("https://horse.spinnov.com:1880/pumpRegister", this.item)
        .then((response) => {
          this.response = response.data.response;
          let state = response.data.state;

          if (state === "error") {
            this.succes = false;
            this.error = true;
          } else if (state === "succes") {
            this.succes = true;
            this.error = false;
          }

          console.log(this.response);
          console.log(this.state);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    cancel() {
      this.$emit("cancelRegister");
    },
    showAlert() {},
  },
};
</script>
