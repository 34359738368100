<template>
  <div id="table-overview">
    <div class="mb-3">
      <h3 class="d-inline-block">Unregister Device</h3>
      <b-button variant="secondary" class="ml-3 mb-2 px-3" @click="cancel()"
        >Cancel</b-button
      >
    </div>
    <!--Searchbar-->
    <div id="searchbar">
      <b-form inline class="justify-content-between">
        <div class="float-left d-inline-flex">
          <b-input-group class="mb-2 mr-sm-2 mb-sm-0 w-50">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              placeholder="Search terms"
            ></b-form-input>
          </b-input-group>
          <b-button variant="primary" id="search-button">Search</b-button>
        </div>
      </b-form>
    </div>

    <!--Alert-->
    <div>
      <b-alert
        :show="dismissCountDown"
        variant="success"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <p>
          Successfully unregistered device! Refreshing page in
          {{ dismissCountDown }} seconds...
        </p>
        <b-progress
          variant="success"
          :max="dismissSecs"
          :value="dismissCountDown"
          height="4px"
        ></b-progress>
      </b-alert>
    </div>
    <!--Unregister TABLE-->
    <div class="table-responsive">
      <b-table class="table" hover :items="pumpRows.pumpData" :fields="fields">
        <!--Specific data for a cell: Identifier-->
        <template #cell(pump_id)="data">
          <b-input-group v-if="data.value == '' || data.value == null">
            -
          </b-input-group>
          <b-input-group v-else> #{{ data.value }} </b-input-group>
        </template>

        <!--Specific data for a cell: STATUS-->
        <template #cell(status)="data">
          <b-icon
            v-if="data.value == 'online'"
            icon="circle-fill"
            variant="success"
          ></b-icon>
          <b-icon v-else icon="circle-fill" variant="danger"></b-icon>
        </template>

        <!--Specific data for a cell: TIME-->
        <template #cell(time_format)="data">
          <b-input-group v-if="data.value == '' || data.value == null">
            -
          </b-input-group>

          <b-input-group v-else>
            {{ data.value }}
          </b-input-group>
        </template>

        <!--Specific data for a cell: Unregister button-->
        <template #cell(unregister_device)="row">
          <b-button
            class="menu-button"
            @click="unregisterDevice(row.item)"
            variant="danger"
          >
            <b-icon icon="trash"></b-icon>
            Unregister Device
          </b-button>
        </template>

        <!--Specific data for a cell: POWER (PLUG OR BATTERY)-->
        <template #cell(power_source)="data">
          <b-input-group v-if="data.value == 'Battery'">
            <b-icon
              icon="circle-fill"
              variant="danger"
              class="mt-1 mr-1"
            ></b-icon>
            Battery
          </b-input-group>

          <b-input-group v-else-if="data.value == 'Mains'">
            Mains
          </b-input-group>

          <b-input-group v-else>
            -
          </b-input-group>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default {
  data: function() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      fields: [
        { key: "qc_number", label: "QC number", sortable: true },
        { key: "location", sortable: true },
        { key: "registration_date" },
        { key: "status", label: "System status" },
        { key: "time_format", label: "Time of Operation" },
        { key: "power_source", label: "Power" },
        "unregister_device",
      ],
      pumpRows: {
        pumpRegistered: [],
        pumpData: [],
      },
      unregister: [],
    };
  },
  methods: {
    cancel() {
      this.$emit("cancelUnregister");
    },
    unregisterDevice(item) {
      this.unregister.push({ qcNumber: item.qc_number });

      axios
        .post(
          "https://horse.spinnov.com:1880/pumpUnregister",
          this.unregister[0]
        )
        .then(function() {})
        .catch(function(error) {
          console.log(error);
        });
      //Empty Array
      this.unregister = [];
      this.showAlert();
    },
    compare(a, b) {
      if (a.pump_id < b.pump_id) {
        return -1;
      }
      if (a.pump_id > b.pump_id) {
        return 1;
      }
      return 0;
    },
    getRegisteredPumps() {
      axios
        .get("https://horse.spinnov.com:1880/registeredPumps")
        .then((response) => {
          this.pumpRows.pumpRegistered = response.data;

          //Format registration date
          for (var i = 0; i < this.pumpRows.pumpRegistered.length; i++) {
            this.pumpRows.pumpRegistered[i].date = this.pumpRows.pumpRegistered[
              i
            ].date.substring(
              0,
              this.pumpRows.pumpRegistered[i].date.indexOf(".")
            );
            this.pumpRows.pumpRegistered[i].date = this.pumpRows.pumpRegistered[
              i
            ].date.replace("T", " ");
          }

          //Sort data on pump id
          this.pumpRows.pumpRegistered.sort(this.compare);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getData() {
      axios
        .get("https://horse.spinnov.com:1880/pumpGetData")
        .then((response) => {
          this.pumpRows.pumpData = response.data;

          //Sort data on pump id
          this.pumpRows.pumpData.sort(this.compare);

          for (var i = 0; i < this.pumpRows.pumpData.length; i++) {
            //Format registration date
            let dateString = this.pumpRows.pumpRegistered[i].time.replace(
              " ",
              "T"
            );

            let date = new Date(dateString);
            let minutes =
              date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes();

            let seconds =
              date.getSeconds() < 10
                ? "0" + date.getSeconds()
                : date.getSeconds();

            dateString =
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getDate() +
              " " +
              date.getHours() +
              ":" +
              minutes +
              ":" +
              seconds;

            //Add data from registration table: date
            this.pumpRows.pumpData[i].registration_date = dateString;

            //Add data from registration table: location
            this.pumpRows.pumpData[i].location = this.pumpRows.pumpRegistered[
              i
            ].location;

            //convert to Day, Hours, Minutes
            var days = Math.floor(
              this.pumpRows.pumpData[i].pump_time_of_operation / 1440
            );
            var excludeDays =
              this.pumpRows.pumpData[i].pump_time_of_operation - 1440 * days;
            var time =
              days +
              "d " +
              Math.floor(excludeDays / 60) +
              "h " +
              (excludeDays % 60) +
              "m";
            Vue.set(this.pumpRows.pumpData[i], "time_format", time);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;

      if (this.dismissCountDown == 0) {
        location.reload();
      }
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
  //when loading, will load getlist method
  created() {
    //Get registration data
    this.getRegisteredPumps();
    //Get pump data
    this.getData();
  },
};
</script>

<style scoped>
#table-overview {
  width: 100%;
  margin: 0 auto;
}

#searchbar {
  width: 100%;
  background-color: lightgrey;
  padding: 10px;
  border-radius: 4px;
}

#search-button {
  width: 150px;
}
</style>
